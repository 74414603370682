blueimp.Gallery.prototype.textFactory = function (obj, callback) {
	var $element = $('<div>')
		.addClass('text-content')
		.attr('title', obj.title);
	$.get($(obj).data('lightbox'))
		.done(function (result) {
			$element.html(result);
			leaderbird.copyPaste();
			callback({
				type: 'load',
				target: $element[0]
			});
		})
		.fail(function () {
			callback({
				type: 'error',
				target: $element[0]
			});
		});
	return $element[0];
};

$(document).ready(function() {
	
	$('#blueimp-gallery').on('slidecomplete', function (event, index, slide) {
		return event;
	});

	SocialShareKit.init();

	// Spinner
	new Spinner().spin(document.getElementById('infinite-scrolling-loading-spinner'));

	var $grid = $('#grid').masonry({
		// set itemSelector so .grid-sizer is not used in layout
		itemSelector: '.grid-item',
		// use element for option
		//columnWidth: '.grid-sizer',
		percentPosition: true
	});

	// layout Masonry after each image loads
	$grid.imagesLoaded().progress( function() {
		$grid.masonry('layout');
	});

	// Init ias
	var ias = jQuery.ias({
		container:  '#grid',
		item:       '.grid-item',
		pagination: '.pagination',
		next:       '.next a'
	});

	// Render callback for masonry
	ias.on('load', function(items) {
		$('#infinite-scrolling-loading').removeClass('hidden');
	});

	ias.on('render', function(items) {
		$(items).css({ opacity: 0 });
	});

	ias.on('rendered', function(items) {
		$grid.append(items).imagesLoaded(function(){
			$grid.masonry( 'appended', items).masonry();
			$('#infinite-scrolling-loading').addClass('hidden');
		});
	});
});

var leaderbird =
{
	copyPaste: function()
	{
		// Initialize the tooltip.
		$('#copy-button').tooltip();

		// When the copy button is clicked, select the value of the text box, attempt
		// to execute the copy command, and trigger event to update tooltip message
		// to indicate whether the text was successfully copied.
		$('#copy-button').bind('click', function() {
			var input = document.querySelector('#copy-input');
			input.setSelectionRange(0, input.value.length + 1);
			try {
				var success = document.execCommand('copy');
				if (success) {
					$('#copy-button').trigger('copied', ['Copied!']);
				} else {
					$('#copy-button').trigger('copied', ['Copy with Ctrl-c']);
				}
			} catch (err) {
				$('#copy-button').trigger('copied', ['Copy with Ctrl-c']);
			}
		});

		// Handler for updating the tooltip message.
		$('#copy-button').bind('copied', function(event, message) {
			$(this).attr('title', message)
				.tooltip('fixTitle')
				.tooltip('show')
				.attr('title', "Copy to Clipboard")
				.tooltip('fixTitle');
		});
	}
}